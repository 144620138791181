
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import qs from 'qs'
import { OpenStatus, RouterName } from '@/enum'
import { queryFn } from '@/graphql/index'
import { isInAppWebview } from '@/utils/native-app'
import * as storage from '@/utils/storage'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const openCommon = computed(() => store.getters.openCommon)
    const result = ref()

    const stepList: any = {
      1: RouterName.ID_CARD,
      2: RouterName.ID_CARD_INFO,
      3: RouterName.CONTACT_INFO,
      4: RouterName.FINANCIAL_INFO,
      5: RouterName.TAX_INFO,
      6: RouterName.RISK,
      7: RouterName.OTHER,
      8: RouterName.PI,
      9: RouterName.PI_RISK,
      10: RouterName.PI_AR,
      11: RouterName.STATEMENT,
      12: RouterName.CAPTCHA,
      13: RouterName.CONFIRM,
      14: RouterName.TRANSFER_STEP,
      15: RouterName.BIND_CARD,
      16: RouterName.TRANSFER,
      17: RouterName.TRANSFER_PRO,
      18: RouterName.ACCOUNT_TYPE
    }

    const renderRouter = (res: any) => {
      const { data, ok } = res
      if (ok) {
        const { applicationStatus } = data
        // 未提交
        if (applicationStatus === OpenStatus.NO_SUBMIT) {
          if (isInAppWebview()) {
            const name = stepList[openCommon.value.step]
              ? stepList[openCommon.value.step]
              : RouterName.ID_TYPE
            router.replace({ name, query: { type: route.query.type } })
          } else {
            router.replace({ name: RouterName.STATUS, query: { type: route.query.type } })
          }
        } else if (applicationStatus === OpenStatus.OPEN_REJECT) {
          // 驳回
          if (isInAppWebview()) {
            router.replace({ name: RouterName.ID_TYPE, query: { type: route.query.type } })
          } else {
            router.replace({ name: RouterName.STATUS, query: { type: route.query.type } })
          }
        } else if (applicationStatus === OpenStatus.SUPPLEMENT) {
          if (isInAppWebview()) {
            router.replace({ name: RouterName.TRANSFER_STEP, query: { type: route.query.type, hide: 1 } })
          } else {
            router.replace({ name: RouterName.STATUS, query: { type: route.query.type, hide: 1 } })
          }
        } else if (applicationStatus === OpenStatus.ABOLISH) {
          if (isInAppWebview()) {
            router.replace({ name: RouterName.ID_TYPE, query: { type: route.query.type } })
          } else {
            router.replace({ name: RouterName.STATUS, query: { type: route.query.type } })
          }
        } else {
          router.replace({ name: RouterName.STATUS, query: { type: route.query.type } })
        }
      }
    }

    watch(
      () => result.value,
      newValue => {
        renderRouter(newValue)
      }
    )

    watch(() => route.query, (val) => {
      if (val?.type) {
        storage.set('formType', val.type)
      }
    }, { deep: true, immediate: true })

    const openAccountTypeMap:any = {
      openAccount: 1, // 普通开户
      openImmigrantAccount: 9 // 移民开户
    }

    onMounted(async () => {
      const { search } = window.location
      const { call_back_url: callbackUrl, type } = qs.parse(search, { ignoreQueryPrefix: true })
      console.log(type, route.query.type, openAccountTypeMap[route.query.type as string], 'type')
      if (callbackUrl === 'view_reason') {
        router.replace({ name: RouterName.REASON, query: { type: route.query.type } })
      } else {
        result.value = await queryFn('customerOpenStatus', {
          openAccountType: openAccountTypeMap[route.query.type as string] || 1
        })
      }
    })

    return {}
  }
})
